.wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
}

.menuTitle {
    text-align: center;
    font-size: 64px;
    font-family: 'Steclo';
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tables {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 1414px;
    height: 861px;
    zoom: .9;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-image: url(../../assets/img/entry.png);
}

.table {
    width: 200px;
    height: 200px;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem;
    user-select: none;
    cursor: pointer;
}

.name {
    font-size: 36px;
    font-family: 'Steclo';
    margin-bottom: 1rem;
    font-weight: 600;
    text-align: center;
}

.seats {
    font-size: 36px;
    font-family: 'Steclo';
    text-align: center;
}

.number_1 {
    width: 296px;
    height: 214px;
    position: absolute;
    left: 470px;
    top: 56px;
}

.number_2 {
    width: 296px;
    height: 214px;
    position: absolute;
    left: 791px;
    top: 56px;
}

.number_3 {
    width: 114px;
    height: 214px;
    position: absolute;
    left: 1136px;
    top: 56px;
}

.number_4 {
    width: 320px;
    height: 99px;
    position: absolute;
    left: 460px;
    top: 355px;
}

.number_5 {
    width: 320px;
    height: 99px;
    position: absolute;
    left: 780px;
    top: 355px;
}

.number_6 {
    width: 320px;
    height: 195px;
    position: absolute;
    left: 460px;
    top: 515px;
}

.number_7 {
    width: 320px;
    height: 195px;
    position: absolute;
    left: 780px;
    top: 515px;
}

.number_12 {
    width: 205px;
    height: 205px;
    position: absolute;
    left: 170px;
    top: 356px;
    border-radius: 2rem;
}

.active {
    border-radius: 1rem;
    border: 5px solid #18a755;
    opacity: 1;
    transition: .25s;
}