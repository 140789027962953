@import './reset.scss';
@import './variables/global.scss';
@import './theme/normal.scss';
@import './theme/dark.scss';

.app {
    font-family: var(--font-m);
    background-color: var(--bg-color);
    color: var(--primary-color);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: red;
}