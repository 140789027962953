.name {
    position: absolute;
    top: 0rem;
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 101;
}

.nameBlock {
    width: 80%;
    height: auto;
    background-color: #fff;
    border-radius: 1rem;    
    font-family: 'Steclo';
    font-size: 32px;
    text-align: center;
    padding: 2rem;
}

.nameTitle {
    display: block;
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.nameInput {
    padding: 1rem;
    display: block;
    width: 100%;
    font-size: 32px;
    border: 3px solid #000;
    resize: none;
}

.nameButton {
    cursor: pointer;
    font-size: 32px;
    font-weight: 600;
    width: 200px;
    border-radius: 1rem;
    border-style: solid;
    margin-top: 1rem;
    padding: .25rem;
    margin: 2rem 0 1rem auto;
}

.keyboard {
    font-size: 32px;
}