.wrapper_bg {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, .55);
    z-index: 100;
}

.wrapper {
    width: 80%;
    height: auto;
    background-color: #fff;
    border-radius: 1rem;    
    font-family: 'Steclo';
    font-size: 32px;
    text-align: center;
    padding: 2rem;
}

.title {
    display: block;
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.text {
    padding: 1rem;
    display: block;
    width: 100%;
    height: 250px;
    font-size: 32px;
    border: 3px solid #000;
    resize: none;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.ok {
    cursor: pointer;
    font-size: 32px;
    font-weight: 600;
    width: 200px;
    border-radius: 1rem;
    border-style: solid;
    margin-top: 1rem;
    padding: .25rem;
}

.keyboard {
    font-size: 32px;
}