.wrapper {
    display: flex;
    width: fit-content;
}

.tableName {
    text-transform: uppercase;
    font-size: 30px;
    font-family: 'Steclo';
    font-weight: 600;
    text-align: center;
    background-color: #105e8e;
    color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    margin-left: 2rem;
    user-select: none;
    cursor: pointer; 
}