.clock {
    background-color: rgba(0, 0, 0, .55);
    font-size: 2rem;
    margin: 2rem 0 0 0;
    padding: .5rem 1rem;
    user-select: none;
}

.date {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 36px;
    font-family: 'Steclo';
}
@media only screen and (max-width: 400px) {
    .clock {
        font-size: 1.2rem;
    }
    .clock span {
        font-size: 40px;
    }
}
