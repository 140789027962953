.bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.wrapper {
    border-radius: 1rem;
    overflow: hidden;
    width: 100%;
    height: 900px;
    position: relative;
}

.close {
    z-index: 100;
    position: absolute;
    top: 6rem;
    left: 18rem;
    text-transform: uppercase;
    font-size: 30px;
    font-family: 'Steclo';
    font-weight: 600;
    text-align: center;
    background-color: #105e8e;
    color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    margin: 0 1rem 0 auto;
    user-select: none;
    cursor: pointer; 
    width: fit-content;
    display: flex;
    align-items: center;
    line-height: 1;
}

.list {
    overflow-y: auto;
    overflow-x: hidden;
    height: 98%;
    width: 100%;    
    user-select: none;
    padding: 1rem 0;
}

.list::-webkit-scrollbar{
    background-color: rgba(238, 238, 238, 0.562);
    width: .8rem;
    height: .8rem;
  }
  
.list::-webkit-scrollbar-thumb {
    background-color: #376fb9;
    border-radius: 1rem;
}

.itemBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.item {    
    position: relative;
    margin: 1rem 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 390px;
    height: 180px;
    background-color: gray;
    border: 5px solid #fff;
    border-radius: .5rem;
}

.item_inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    background-color: #00000032;
    height: 100%;
    padding-right: 1rem;
}

.item:hover {
    transition: .5s;
    box-shadow: 7.314px 6.82px 16px 0px rgba(17, 51, 68, 0.21);  
}

.image {
    width: 100px;
    display: block;
    object-fit: contain;
}

.itemAbout {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    width: fit-content;
}

.title {
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    font-family: "Steclo";
}

.composition {
    font-weight: 500;
    font-size: 1.4rem;
    font-family: "Steclo";
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.section_title {
    font-family: "Steclo";
    font-weight: 600;
    font-size: 3rem;
    margin: 2rem 0 0;
    width: 100%;
    text-align: center;    
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.itemPrice {
    font-family: "Steclo";
    font-weight: 600;
    font-size: 2rem;
    width: 80px;
    text-align: right;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}