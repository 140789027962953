:root {
    --font-family-main: 'Consolas', 'Times New Roman', Serif;
    
    --font-size-m: 16px;
    --font-line-m: 24px;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

    --font-size-l: 24px;
    --font-line-l: 32px;
    --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);
}