.navbar {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.logo {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 560px;
}

.info {
    font-family: "Steclo";
    font-weight: 200;
    position: absolute;
    left: 146px;
    top: 1rem;
    display: flex;
    flex-direction: column;
}

.infoTitle {
    color: #43250b;
    font-size: 40px;
}

.time {
    color: #43250b;
    font-size: 30px;
}