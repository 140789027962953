.menu_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1rem;
}

.menu_row_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 18vh;
}

.menuBlock:hover .menu_popup {
    display: flex;
}

.menuBlock {
    position: relative;
    margin: .5rem 2rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100%;
    background-color: gray;
}

.menuBlock__Mid {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

.foodicon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 80px;
    color: #fff;
    margin-top: 1rem;
    user-select: none;
    cursor: pointer;
    opacity: .5;
}

.menu_popup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .55);
    color: #fff;
}

// .menu_popup_no_hover {
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 2;
//     width: 100%;
//     height: 100%;
//     display: none;
//     align-items: center;
//     justify-content: center;
//     background-color: rgba(0, 0, 0, .55);
//     color: #fff;
// }

.count_minus, .count_plus {
    user-select: none;
    cursor: pointer;
    font-size: 50px;
    font-family: Arial, Helvetica, sans-serif;
    height: 100px;
}

.count {
    height: 150px;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 100px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 1rem;
}

.menu_text {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, .55);
    text-align: center;
}

.menu_title {
    font-size: 29px;
    font-family: 'Steclo';
    user-select: none;
}

.menu_description {
    font-size: 22px;
    font-family: 'France';
    user-select: none;
}