.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, .75);
    z-index: 1000;
}

.timer {
    font-size: 15rem;
    color: #fff;
    font-family: 'Steclo';
    font-weight: 800;
    background-color: #105e8e;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px;
}

/* .wrapper_def {
    z-index: 999;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .75);
} */