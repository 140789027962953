.wrapper {
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url(../../assets/img/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    user-select: none;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.navbar_btn {
    cursor: pointer;
    margin-bottom: 40px;
    width: 108px;
    height: 108px;
    border-style: solid;
    border-width: 9px;
    border-color: #bfdff3;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 7.314px 6.82px 32px 0px rgba(17, 51, 68, 0.41),inset 5.416px 7.188px 21px 0px rgba(0, 0, 0, 0.35);      
    display: flex;
    align-items: center;
    justify-content: center;
    color: #43250b;
    font-size: 32px;
    font-family: 'Steclo';
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 11;
    user-select: none;
}

.menu {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 83vh;
    overflow: auto;
    margin-top: 5rem;
}

.menu_img__Mid {
    position: relative;
    width: 110%;
    z-index: 1;
    text-align: center;
}

.titleRow {
    z-index: 10;
    position: fixed;
    width: 300px;
    box-shadow: 7.314px 6.82px 32px 0px rgba(17, 51, 68, 0.41),inset 5.416px 7.188px 21px 0px rgba(0, 0, 0, 0.35);
    border-style: solid;
    border-width: 9px;
    border-color: rgb(191, 223, 243);
    background-color: rgb(255, 255, 255);
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 100px 100px 0;
    margin-left: -9px;
}

.request {
    position: absolute;
    top: 0;
    right: 0; 
    z-index: 3;
}

.requestList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: auto;
    background-color: #fff;
    padding: 1rem 1rem;
    width: 390px;
}

.requestItem {
    font-size: 24px;
    font-weight: 600;
    padding: .25rem 0;
    font-family: 'Steclo';
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.requestButton {
    position: absolute;
    right: 4rem;
    bottom: 1.5rem;
    cursor: pointer;
    width: 250px;
    height: 104px;
    text-transform: uppercase;
    font-family: 'Steclo';
    font-size: 24px;
    box-shadow: 7.314px 6.82px 32px 0px rgba(17, 51, 68, 0.41),inset 5.416px 7.188px 21px 0px rgba(0, 0, 0, 0.35);
    border-style: solid;
    border-width: 9px;
    border-color: rgb(191, 243, 206);
    background-color: rgb(255, 255, 255);
    display: flex;
    color: #43250b;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    z-index: 100;
}

.menu_title__Mid {
    color: #105e8e;
    font-size: 40px;
    text-align: center;
    font-family: 'France';
}

.menu::-webkit-scrollbar, .menu::-moz-scrollbar {
    //width: .25rem !important;
    width: 0px !important;
    height: 0px !important;
}
  
.menu::-webkit-scrollbar-track, .menu::-moz-scrollbar-track {
    background-color: #eee !important;
    border-radius: 1rem  !important;
}

.menu::-webkit-scrollbar-thumb, .menu::-moz-scrollbar-thumb {
    background-color: #105e8e  !important;
    border-radius: 1rem  !important;
}

.back {
    position: absolute;
    top: 6rem;
    right: 27rem;
}

.openMenu {
    z-index: 100;
    position: absolute;
    top: 6rem;
    left: 18rem;
    text-transform: uppercase;
    font-size: 30px;
    font-family: 'Steclo';
    font-weight: 600;
    text-align: center;
    background-color: #105e8e;
    color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    margin: 0 1rem 0 auto;
    user-select: none;
    cursor: pointer; 
    width: fit-content;
    display: flex;
    align-items: center;
    line-height: 1;
}