.request_bg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, .55);
}

.request_popup {
    position: absolute;
    width: 90%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    color: #fff;
    z-index: 100;
    background-color: #216892;
    padding: 3rem;
}

.requestTitle {
    font-size: 70px;
    font-family: "France";
    text-align: center;
}

.requestList {
    width: 80%;
    text-align: right;
    overflow-y: auto;
}

.requestItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: .5rem 0;
    width: 100%;
    font-family: 'Steclo';
    font-size: 36px;
    text-align: right;
    padding-right: 1rem;
}

.price {
    margin-top: 2rem;
    font-size: 36px;
    font-family: 'Steclo';
}

.endText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.cancel {
    cursor: pointer;
    font-size: 40px;
    font-weight: 200;
    font-family: "Steclo";
    text-align: center;
    width: 150px;
    text-transform: uppercase;
    text-decoration: underline;
    user-select: none;
}

.off {
    font-size: 45px;
    font-family: "France";
    text-align: center;
    user-select: none;
}

.ok {
    user-select: none;
    cursor: pointer;
    font-size: 70px;
    font-family: "Steclo";
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    position: relative;
}

.mint {
    position: absolute;
    right: 1rem;
    bottom: 2rem;
    z-index: -1;
}